import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';

export type RodoType = {
	data: {
		parent: {
			content: string;
			title: string;
		},
	}	
}

const StyledRodoWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: ${({ theme }) => theme.paddingDesktop}; 
    margin-bottom: 70px;
    position: relative;

    @media (max-width: 767.98px) {
        padding: ${({ theme }) => theme.paddingPhone};   
        margin-bottom: 30px; 
        p {
            font-size: 14px;
        }
    } 
    @media (min-width: 768px) and (max-width: 991.98px) {
        padding: ${({ theme }) => theme.paddingTablet}; 
        margin-bottom: 70px;
    }
    @media (min-width: 992px) and (max-width: 1600px) {
        padding: ${({ theme }) => theme.paddingLaptop}; 
        margin-bottom: 70px;
    }
`

const Rodo = styled.div`
    width: 70%;
    padding-left: 100px;
    display: flex;
    flex-direction: column;

    @media (min-width: 992px) and (max-width: 1100px) {
        width: 90%;
        padding-left: 0;
    }

    @media (max-width: 767.98px) {
        width: 100%;
        padding-left: 0;
    } 
    @media (min-width: 768px) and (max-width: 991.98px) {
        width: 100%;
        padding-left: 0;
    }
`

const SectionTitle = styled.h2`
    font-size: 46px;
    font-weight: 600;
    font-family: Museo;
    color: ${({ theme }) => theme.primaryColor};
    line-height: 1.4;
    padding-top: 300px;
    padding-bottom: 170px;
    width: 80%;

    @media (min-width: 992px) and (max-width: 1100px) {
        font-size: 46px;
    }

    @media (max-width: 767.98px) {
        font-size: 40px;
    } 
    @media (min-width: 768px) and (max-width: 991.98px) {
        font-size: 46px;
    }
`;
 
const PrivacyPolity: React.FC<RodoType> = ({data}) => {
    const {t} = useTranslation();
    
    return ( 
        <Layout>
            <SEO title={data.parent.title} description= {data.parent.title}/>
            <StyledRodoWrapper>
            <SectionTitle>{t('rodoSubtitle')}</SectionTitle>
            <Rodo dangerouslySetInnerHTML={{ __html: data.parent.content}}></Rodo>
        </StyledRodoWrapper>
    </Layout>
     );
}

export const pageQuery = graphql`
query($language: String!) {
    parent: wpPage(title: {eq: "RODO"}, language: {slug: {eq: $language}}) {
        title
        content
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
        edges {
            node {
            ns
            data
            language
            }
        }
    }
}`

export default PrivacyPolity;